import { css } from '@emotion/react';
import {
  Box,
  type MantineTheme,
  desktopFirstMediaQuery,
  useTheme,
  COLOR_PALETTE
} from '@inflearn/ds-react';
import { Link } from 'gatsby';
import { GATSBY_ANT_MAN_URL, GATSBY_CDN_URL, GATSBY_RALLIT_DOMAIN } from '../../../../utils/env';
import { URLS } from '../../../../utils/urls';

const IntegratedHeader = () => {
  const theme = useTheme();
  const integratedHeaderLinkStyle = IntegratedHeaderLinkStyle(theme);
  return (
    <Box component="section" css={styleIntegratedHeader(theme)}>
      <Box
        css={{
          width: '100%',
          maxWidth: 1200
        }}>
        <Box css={styleIntegratedHeaderBody(theme)}>
          <Box component="ul" css={{ display: 'flex' }}>
            <Box css={integratedHeaderLinkStyle} component="li">
              <a href={GATSBY_ANT_MAN_URL} rel="noreferrer">
                <img
                  src={`${GATSBY_CDN_URL}/infcon/infcon-2023/main-header-gray-inflearn.svg`}
                  css={{ marginRight: '4px' }}
                />
                교육
              </a>
            </Box>
            <Box css={integratedHeaderLinkStyle} component="li">
              <a href={`${GATSBY_RALLIT_DOMAIN}/my/log`} rel="noreferrer">
                <RallitLogo fill={theme.colors.gray[6]} />
                커리어
              </a>
            </Box>
            <Box css={integratedHeaderLinkStyle} component="li">
              <Link to={URLS.INFCON_2024__HOME} className="active">
                INFCON
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

function RallitLogo({ fill }: { fill?: string }) {
  return (
    <svg
      fill="none"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 10.7626C3 7.3177 5.76662 4.52502 9.17943 4.52502H12.7955V7.11248H9.17943C7.1823 7.11248 5.56332 8.74672 5.56332 10.7626C5.56332 12.7786 7.1823 14.4128 9.17943 14.4128C11.1765 14.4128 12.7955 12.7786 12.7955 10.7626V7.11248H15.3589V10.7626C15.3589 14.2076 12.5922 17.0003 9.17943 17.0003C5.76662 17.0003 3 14.2076 3 10.7626Z"
        fill={fill ?? '#21252A'}
      />
      <path
        d="M15.4272 5.91089C16.2235 5.91089 16.8691 5.25927 16.8691 4.45545C16.8691 3.65163 16.2235 3 15.4272 3C14.6309 3 13.9854 3.65163 13.9854 4.45545V5.91089H15.4272Z"
        fill={fill ?? COLOR_PALETTE.infgreen[6]}
      />
    </svg>
  );
}

const IntegratedHeaderLinkStyle = (theme: MantineTheme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ':not(:last-child):after': {
      content: "''",
      display: 'block',
      margin: '0px 8px',
      width: '1px',
      height: '12px',
      background: theme.colors.gray[4]
    },
    ':not(:first-of-child)': {
      marginLeft: '2px'
    },

    a: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '2px',
      color: theme.colors.gray[6],
      fontWeight: '500',
      lineHeight: '1.43',
      letterSpacing: '-.3px',
      fontSize: 14,
      '&.active': {
        color: theme.colors.gray[9],
        fontWeight: 700
      }
    }
  });

export default IntegratedHeader;
const styleIntegratedHeaderBody = (theme: MantineTheme) =>
  css({
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '0 32px',
    maxWidth: 1200,
    color: theme.colors.gray[7],
    [desktopFirstMediaQuery.mobile]: {
      padding: '0 16px'
    }
  });

const styleIntegratedHeader = (theme: MantineTheme) =>
  css({
    position: 'relative',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.white,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    zIndex: 30
  });
